<template>
  <header>
    <div class="container header">
      <div class="logo">
        <router-link :to="{ name: 'home' }">
          <img src="@/assets/img/logo.svg" alt="logo" />
        </router-link>
      </div>
      <div class="header-links">
        <router-link
          @mouseover="showCertification = true"
          @mouseleave="showCertification = false"
          :to="{ name: 'home' }"
          class="header-link certification-link"
        >
          Certification
          <div class="header-dropdown" v-show="showCertification">
            <router-link
              :to="{
                name: 'certificationDetail',
                params: { certificationId: certification.id },
              }"
              class="header-dropdown__link"
              v-for="certification in $store.state.certificationCountries"
              :key="certification.id"
            >
              <img
                :src="certification.country.photo"
                :alt="`${certification.country.name} icon`"
              />
              {{ certification.country.name }}
            </router-link>
          </div>
        </router-link>
        <router-link
          :to="{ name: link.routeName }"
          :key="index"
          v-for="(link, index) in $store.state.headerInternalLinks"
          class="header-link"
        >
          {{ link.name }}
        </router-link>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "HeaderVue",
  data() {
    return {
      showCertification: false,
    };
  },
});
</script>

<style lang="sass" scoped>
@import "@/assets/sass/base/base"

header
  background-color: $headerBgColor
  position: fixed
  width: 100%
  opacity: .95
  z-index: 99
  top: 0

  .header
    padding: 10px 0
    color: $headerFgColor
    @include flexAiCenterJcSb

    & .logo
      width: 15%

      & img
        max-width: 100%

    & .header-links
      width: 80%
      display: flex
      align-items: center
      justify-content: space-between

      & .header-link
        font-weight: bold
        font-size: 22px
        color: $headerLinkFg
        padding: 15px 40px
        transition: all .5s ease

        &:hover
          color: $headerHoverLinkFg
          background-color: $headerHoverLinkBg

.certification-link
  position: relative
</style>
