<template>
  <div class="footer-container">
    <footer class="container">
      <div class="left">
        <div class="logo">
          <router-link :to="{ name: 'home' }">
            <img src="@/assets/img/logo.svg" class="logo" alt="logo" />
          </router-link>
        </div>
        <div class="footer-links">
          <p
            class="footer-link"
            :key="index"
            v-for="(link, index) in $store.state.headerInternalLinks"
          >
            <router-link :to="{ name: link.routeName }">
              {{ link.name }}
            </router-link>
          </p>
        </div>
      </div>
      <div class="right">
        <div class="social-links">
          <p
            class="social-link"
            :key="index"
            v-for="(socialLink, index) in $store.state.socialLinks"
          >
            <a :href="socialLink.link">
              <img :src="socialLink.iconPath" alt="social" />
            </a>
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "FooterVue",
});
</script>

<style lang="sass" scoped>
@import "@/assets/sass/base/base"

.footer-container
  background-color: $footerBg
  color: $footerContentFg
  position: absolute
  bottom: 0
  width: 100%
  height: 250px

footer
  padding-top: 25px
  display: flex
  justify-content: space-between
  align-items: flex-start

  & .left,
  & .right
    width: 40%

  & .left
    @include flexAiCenterJcSb

    & .logo
      width: 40%

      & img
        width: 100%

    & .footer-links
      width: 55%

      & .footer-link
        margin-bottom: 20px

        & a
          color: $footerContentFg

  & .right
    @include flexAiCenterJcSb

    & .social-links
      width: 100%
      display: flex
      align-items: center
      justify-content: center

      & .social-link
        margin-right: 25px
</style>
