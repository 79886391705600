import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6cc266fe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "partners-container" }
const _hoisted_2 = { class: "partners" }
const _hoisted_3 = ["src", "alt", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.partners, (partner) => {
        return (_openBlock(), _createElementBlock("img", {
          key: partner.id,
          class: "partner",
          src: partner.photo,
          alt: partner.name,
          onClick: ($event: any) => (_ctx.redirect(partner.site_link))
        }, null, 8, _hoisted_3))
      }), 128))
    ])
  ]))
}