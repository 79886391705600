<template>
  <HeaderVue />
  <router-view />
  <FooterVue />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import HeaderVue from "@/components/UI/HeaderVue.vue";
import FooterVue from "@/components/UI/FooterVue.vue";

export default defineComponent({
  name: "App",
  components: { FooterVue, HeaderVue },
  async created() {
    await this.$store.dispatch("getCountryCertifications");
  },
});
</script>
