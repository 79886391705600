import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4dd14a01"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "news-card" }
const _hoisted_2 = { class: "news-photo" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "news-header" }
const _hoisted_6 = { class: "news-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.newsPhoto,
        alt: "news photo"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h2", _hoisted_5, _toDisplayString(_ctx.newsHeader), 1),
      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.newsTitle), 1)
    ]),
    _createVNode(_component_router_link, {
      to: { name: 'newsDetail', params: { newsId: _ctx.newsId } },
      class: "news-detail-btn"
    }, {
      default: _withCtx(() => [
        _createTextVNode("Read more")
      ]),
      _: 1
    }, 8, ["to"])
  ]))
}