import { createStore } from "vuex";
import { News } from "@/components/News/types";
import Legislation from "@/components/CountryLegislation/types";
import { ApiHost } from "@/vars";
import { Slide } from "@/components/UI/banner/types";
import { Partner } from "@/components/Partners/types";
import { CountryBranchCoordinate, HeaderLink, SocialLink } from "@/store/types";
import {
  CertificationInfo,
  CountryDetailInfo,
} from "@/components/Certification/types";

export interface State {
  headerInternalLinks: Array<HeaderLink>;
  socialLinks: Array<SocialLink>;
  news: Array<News>;
  yandexMapApiKey: string;
  certificationCountries: Array<CertificationInfo>;
  countryLegislationList: Array<Legislation>;
  mainPageSlides: Array<Slide>;
  partners: Array<Partner>;
  centerWorldWideCoords: Array<CountryBranchCoordinate>;
  currentCountryDetailInfo: CountryDetailInfo | null;
}

export default createStore({
  state: {
    headerInternalLinks: [
      {
        name: "Consulting Center worldwide",
        routeName: "worldWide",
      },
      {
        name: "Home",
        routeName: "home",
      },
      {
        name: "Contacts",
        routeName: "contact",
      },
    ],
    socialLinks: [
      {
        iconPath: require("@/assets/img/linkedin-white.svg"),
        link: "#!",
      },
      {
        iconPath: require("@/assets/img/facebook-white.svg"),
        link: "#!",
      },
      {
        iconPath: require("@/assets/img/telegram-white.svg"),
        link: "#!",
      },
    ],
    news: [],
    certificationCountries: [],
    countryLegislationList: [],
    yandexMapApiKey: "c73291ce-c9ac-4cac-89b5-93e7a6911154",
    mainPageSlides: [],
    partners: [],
    centerWorldWideCoords: [],
    currentCountryDetailInfo: null,
  } as State,
  getters: {
    findNewsById: (state: State) => (newsId: number) => {
      return state.news.find((news): News | undefined => {
        if (news.id === newsId) {
          return news;
        }
      });
    },
    findCertificationById: (state: State) => (certificationId: number) => {
      return state.certificationCountries.find(
        (certification): CertificationInfo | undefined => {
          if (certification.id === certificationId) {
            return certification;
          }
        }
      );
    },
    getCoordsAsArray(state) {
      const result: Array<number[]> = [];
      state.centerWorldWideCoords.forEach((value) => {
        result.push([value.latitude, value.longitude]);
      });
      return result;
    },
  },
  mutations: {
    setMainPageSlides(state, slides: Array<Slide>) {
      state.mainPageSlides = slides;
    },
    setNews(state, news: Array<News>) {
      state.news = news;
    },
    setPartners(state, partners: Array<Partner>) {
      state.partners = partners;
    },
    setCenterCoords(state, coords: Array<CountryBranchCoordinate>) {
      state.centerWorldWideCoords = coords;
    },
    setCountryCertifications(
      state,
      certificationInfos: Array<CertificationInfo>
    ) {
      state.certificationCountries = certificationInfos;
    },
    setCurrentCountryDetail(state, info: CountryDetailInfo) {
      state.currentCountryDetailInfo = info;
    },
    setCurrentCountryLaws(state, legislation: Array<Legislation>) {
      state.countryLegislationList = legislation;
    },
  },
  actions: {
    async getApiSlides({ commit }) {
      await fetch(`${ApiHost}/api/cms/slides`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw Error("Ошибка при получении слайдов");
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .then((jsonResponse) => {
          commit("setMainPageSlides", jsonResponse as Array<Slide>);
        });
    },
    async getApiNews({ commit }) {
      await fetch(`${ApiHost}/api/news`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw Error("Не удалось получить новости");
          }
        })
        .catch((err) => console.log(err))
        .then((jsonResponse) => {
          commit("setNews", jsonResponse as Array<News>);
        });
    },
    async getApiPartners({ commit }) {
      await fetch(`${ApiHost}/api/cms/partners`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw Error("Не удалось получить партнёров");
          }
        })
        .catch((err) => console.log(err))
        .then((jsonResponse) => {
          commit("setPartners", jsonResponse as Array<Partner>);
        });
    },
    async getApiCenterCoords({ commit }) {
      await fetch(`${ApiHost}/api/cms/branches/`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw Error("Не удалось получить точек");
          }
        })
        .catch((err) => console.log(err))
        .then((jsonResponse) => {
          commit(
            "setCenterCoords",
            jsonResponse as Array<CountryBranchCoordinate>
          );
        });
    },
    async getCountryCertifications({ commit }) {
      await fetch(`${ApiHost}/api/certifications/country-certification/`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw Error("Не удалось найти сертификаты");
          }
        })
        .catch((err) => console.log(err))
        .then((jsonResponse) => {
          commit(
            "setCountryCertifications",
            jsonResponse as Array<CertificationInfo>
          );
        });
    },
    async getCountryDetailInfo({ commit }, payload) {
      await fetch(`${ApiHost}/api/certifications/country/${payload.countryId}`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw Error("Не удалось найти страну");
          }
        })
        .catch((err) => console.log(err))
        .then((jsonResponse) => {
          commit("setCurrentCountryDetail", jsonResponse as CountryDetailInfo);
        });
    },
    async getCountryLaws({ commit }, payload) {
      await fetch(
        `${ApiHost}/api/legislation/laws/by-country/${payload.countryId}`
      )
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            throw Error("Не удалось получить законы страны");
          }
        })
        .catch((err) => console.log(err))
        .then((jsonResponse) => {
          commit("setCurrentCountryLaws", jsonResponse as Array<Legislation>);
        });
    },
  },
  modules: {},
});
