<template>
  <section class="partners-container">
    <div class="partners">
      <img
        v-for="partner in $store.state.partners"
        :key="partner.id"
        class="partner"
        :src="partner.photo"
        :alt="partner.name"
        @click="redirect(partner.site_link)"
      />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PartnersVue",
  methods: {
    redirect(url: string) {
      window.open(url);
    },
  },
});
</script>

<style lang="sass" scoped>
@import "@/assets/sass/base/base"

.partners-container
  background-color: $partnersBoxBg
  padding: 50px 25px
  margin-top: 50px
  margin-bottom: 100px

.partners
  display: flex
  align-items: center
  flex-wrap: wrap
  justify-content: space-around

  & .partner
    width: 30%
    filter: grayscale(1)

    &:hover
      filter: grayscale(0)

    &:not(:last-child)
      margin-bottom: 25px
</style>
