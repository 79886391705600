<template>
  <div class="news-card">
    <div class="news-photo">
      <img :src="newsPhoto" alt="news photo" />
    </div>
    <div class="content">
      <h2 class="news-header">{{ newsHeader }}</h2>
      <p class="news-title">{{ newsTitle }}</p>
    </div>
    <router-link
      :to="{ name: 'newsDetail', params: { newsId: newsId } }"
      class="news-detail-btn"
      >Read more</router-link
    >
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "NewsCard",
  props: {
    newsId: {
      required: true,
      type: Number,
    },
    newsHeader: {
      required: true,
      type: String,
    },
    newsTitle: {
      required: true,
      type: String,
    },
    newsPhoto: {
      required: true,
      type: String,
    },
  },
});
</script>

<style lang="sass" scoped>
@import "@/assets/sass/base/base"

.news-card
  border: 1px solid $newsCardBorderColor
  word-break: break-word
  padding-bottom: 10px
  transition: all .5s ease
  display: flex
  flex-direction: column
  justify-content: space-between

  &:hover
    background-color: $newsCardHoverBg
    & .content .news-title
      color: $newsCardContentHoverFg

    & .news-detail-btn
      color: $newsCardReadMoreHoverFg

    & .content .news-header
      color: $newsCardHeaderHoverFg

  & .news-photo,
  & .news-photo img
    width: 100%

  & .content
    padding: 10px

    & .news-header
      color: $newsCardHeaderFg
      margin-bottom: 20px

    & .news-title
      font-size: 18px
      margin-bottom: 20px
      overflow: hidden
      display: -webkit-box
      -webkit-line-clamp: 3
      -webkit-box-orient: vertical

  & .news-detail-btn
    color: $newsCardBtnFg
    padding: 10px
    margin: 10px
    width: 40%

    &:hover
      color: $newsCardBtnFg
      background-color: $newsCardBtnBgHover
</style>
