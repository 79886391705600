<template>
  <MainPageBanner />
  <section class="home">
    <div class="container content-container" v-if="$store.state.news.length">
      <h2>News</h2>
      <div class="news">
        <NewsCard
          v-for="(newsItem, index) in $store.state.news"
          :key="index"
          :news-id="newsItem.id"
          :news-header="newsItem.name"
          :news-title="newsItem.description"
          :news-photo="newsItem.photo"
        />
      </div>
    </div>
    <div class="content-container">
      <div class="content-header container">
        <h2>Partners</h2>
      </div>
      <PartnersVue></PartnersVue>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import MainPageBanner from "@/components/UI/banner/MainPage.vue";
import NewsCard from "@/components/UI/cards/NewsCard.vue";
import PartnersVue from "@/components/Partners/PartnersVue.vue";

export default defineComponent({
  name: "HomeView",
  components: { PartnersVue, NewsCard, MainPageBanner },
  async created() {
    await this.$store.dispatch("getApiSlides");
    await this.$store.dispatch("getApiNews");
    await this.$store.dispatch("getApiPartners");
  },
});
</script>

<style lang="sass" scoped>
@import "@/assets/sass/base/base"

.news
  display: flex
  align-items: stretch
  flex-wrap: wrap
  width: 100%
  margin-top: 50px

  & .news-card
    width: 23%
    margin-right: 20px
    margin-bottom: 20px
</style>
