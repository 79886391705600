import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/ContactVue.vue"),
  },
  {
    path: "/news/:newsId(\\d+$)",
    name: "newsDetail",
    component: () => import("@/views/NewsDetail.vue"),
  },
  {
    path: "/map",
    name: "worldWide",
    component: () => import("@/views/ConsultingWorldWide.vue"),
  },
  {
    path: "/legislation/:certificationId(\\d+$)",
    name: "certificationDetail",
    component: () => import("@/views/LegislationDetail.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    component: () => import("@/views/errors/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
