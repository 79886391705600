<template>
  <Splide
    class="main-page-banner"
    :options="sliderOptions"
    aria-label="My Favorite Images"
  >
    <SplideSlide v-for="slide in $store.state.mainPageSlides" :key="slide.id">
      <img :src="slide.photo" :alt="'Banner ' + slide.id" />
    </SplideSlide>
  </Splide>
</template>
<script lang="ts">
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { defineComponent } from "vue";

export default defineComponent({
  name: "MainPageBanner",
  components: { Splide, SplideSlide },
  data() {
    return {
      sliderOptions: {
        rewind: true,
        speed: 1000,
        perPage: 1,
      },
    };
  },
});
</script>

<style lang="sass">
@import "@/assets/sass/base/base"
@import "@splidejs/vue-splide/css"

.main-page-banner
  width: 100% !important
  z-index: 1 !important
  padding-top: 200px !important

  & img
    width: 100%
    height: 700px

.splide__pagination__page
  width: 80px !important
  height: 10px !important
  color: white !important
  background: #cbcbcb !important
  opacity: 1 !important
  margin: 3px 10px 3px 3px !important
  border-radius: 5px !important

  &.is-active
    transform: none !important
    background: white !important

.splide__pagination
  width: 80%
  bottom: 43px !important
  justify-content: right !important

.splide__arrow.splide__arrow--next,
.splide__arrow.splide__arrow--prev
  top: auto !important
  background: none !important
  opacity: .99 !important
  bottom: 0 !important
  border: 1px solid #0c874c
  padding: 10px
  width: 50px
  height: 50px

  & svg
    fill: #0c874c !important

.splide__arrow.splide__arrow--next
  right: 13%

.splide__arrow.splide__arrow--prev
  left: auto
  right: 18%
</style>
