import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SplideSlide = _resolveComponent("SplideSlide")!
  const _component_Splide = _resolveComponent("Splide")!

  return (_openBlock(), _createBlock(_component_Splide, {
    class: "main-page-banner",
    options: _ctx.sliderOptions,
    "aria-label": "My Favorite Images"
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$store.state.mainPageSlides, (slide) => {
        return (_openBlock(), _createBlock(_component_SplideSlide, {
          key: slide.id
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: slide.photo,
              alt: 'Banner ' + slide.id
            }, null, 8, _hoisted_1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["options"]))
}